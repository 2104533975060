import React, { useEffect, useState} from "react";
import {Card, CardContent, CardMedia} from "@mui/material";
import NavBar from "../../components/organisms/NavBar.js";
import { Container, Typography, Button, CircularProgress, Box} from "@material-ui/core";
import Grid from "@mui/material/Grid";
import ImagenEquipo from "../Produccion/maquina.png";
import { makeStyles } from "@material-ui/core/styles";
import BarTimelineChartVacio from "../Produccion/BarTimelineChartVacio";
import MotionChart from "./MotionChart";
import imgCamion from "../Produccion/camion.png";
import imgCamionDumper from "../Produccion/camion-dumper.png";
import ImagenJumbo from "../../assets/images/jumbo.png";
import SectionHeader from "../../components/molecules/SectionHeader.js";
import IconoKpi from '../../assets/icons/icono-kpi.png';
import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoDetalle from '../../assets/icons/icono-detalle.png';
import ProdKpiCard from "../../components/organisms/ProdKpiCard.js";
import IconoVueltas from '../../assets/icons/icono-vueltas.png';
import IconoTonelaje from '../../assets/icons/icono-tonelaje.png';
import IconoRendimiento from '../../assets/icons/icono-rendimiento.png';
import { sortByDate } from "../../commons/Filtros.js";
import TablaV5 from "./Tablav5";

import { getVueltasMaquinaRango, getMovimientosPorMaquina, getEncuentrosMovMaquina } from "../../services/diario";
import { getInfoGeneralMina, getTiemposAsarco} from "../../services/general";
import HorizontalTimeChart from "../Operaciones/HorizontalTimeChart";
import { Redirect, useLocation } from "react-router";
import { format } from "date-fns";

import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import { generateDatabaseDateTimeES, restarDias, sumarDias } from "../../commons/FormatearFecha";

import { PopupRegistroJustificacion } from "../Justificacion/PopupRegistroJustificacion";
import { getUltimaUbicacionJustificacion, insertJustificacion } from "../../services/justificacion";
import { useGetGruposMotivosJustificaciones } from "../../hooks/useGetGruposMotivosJustificaciones";
import { AlertaGenerica } from "../../components/molecules/AlertaGenerica.js";
import { MapaGPS } from "../MapaGPS";
import { useGetEncuentroGPS } from "../../hooks/useGetEncuentroGPS";
import { useContext } from "react";
import { GeneralMinaContext } from "../../context/GeneralMina";
import { TiempoAsarcoGenerico } from "../../components/organisms/TiempoAsarcoGenerico.js";
import { RegistroIngreso } from "../../components/organisms/RegistroIngreso.js";
import { useGetHoraIngresoEgreso } from "../../hooks/useGetHoraIngresoEgreso.js";

import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import TablaVueltasGPS from "./Tablas/TablaVueltasGPS.js";
import { useGetInfoVueltasGPS } from "../../hooks/operacionesMaquinas/Rajo/useGetInfoVueltasGPS.js";
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { LeyendasMovimientos } from "../../components/molecules/LeyendasMovimientos.js";
import { MultipleSimpleIndicator } from "../../components/organisms/MultipleSimpleIndicator.js";
import { useGetMovimientosGPS } from "../../hooks/useGetMovimientosGPS.js";
import { ConvertMinutosToHoras } from "../../commons/FormatearTiempo.js";
import { formatearCantidadDecimales } from "../../commons/Formatos.js";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico.js";
import { usePageTimingContext } from "../../context/PageTimingContext.js";
import { SelectorFechaFlechas } from "../../components/molecules/SelectorFechaFlechas.js";
import { getCargasByIdMaquina } from "../../services/operaciones.js";
import { translateMachineName } from "../../commons/utiles.js";
import { ModalVueltasManual } from "../../components/organisms/ModalVueltasManual.js";
import { useGetViconsByIdOperacion } from "../../hooks/useGetViconsByIdOperacion.js";

import Pool from "../../UserPool";
import { cuentasAdministracionVueltas } from "../../commons/cuentas.js";

const useStyles = makeStyles({
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "14.4px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "3px",
    height: "38px",
    width:"160px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  bordermd:{
    '@media (min-width:1200px)': {
      borderRight:"2px solid #d7d7d7"
    }
  },
  marginmd:{
    '@media (min-width:1200px)': {
      marginLeft:"10px"
    }
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        /* fontSize:"14.4px" */
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
    },
  }
});

const OperacionesMaquina = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();
  const localDatosMaquina = localStorage.getItem("dataOpMaq");
  const localDatosMina = localStorage.getItem("dataOpMina");
  const localFecha = localStorage.getItem("fechaObjetivo");
  const [fechaActual, setFechaActual] = useState( new Date(localFecha) || new Date());
  const [seriesMovilizacionMaquina, setSeriesMovilizacionMaquina] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMovimientos, setLoadingMovimientos] = useState(true);
  const [loadingKpiDiario, setloadingKpiDiario] = useState(false);
  const [reloadCharts, setReloadCharts] = useState(0);
  const [loadHorizontalChart, setLoadHorizontalChart] = useState(false)
  const [rutaMina, setRutaMina] = useState("");
  const [idMina, setIdMina] = useState(0);
  const [nombreMina, setNombreMina] = useState("");
  const [imagenCamion, setImagenCamion] = useState(imgCamion);
  const [listaTurnos, setListaTurnos] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const {onHandleGetGeneralData, onHandleSetDataGeneral} = useContext(GeneralMinaContext);
  const [vueltasMaquinas, setVueltasMaquinas] = useState([]);
  const [reglasVueltas, setReglasVueltas] = useState([]);
  const [VisibleModalVueltasManual, setVisibleModalVueltasManual] = useState(false)
  const { dataIngresoEgreso, loadingIngresoEgreso } = useGetHoraIngresoEgreso(JSON.parse(localDatosMina).idMina,format(fechaActual, "yyyy-MM-dd"),JSON.parse(localDatosMaquina).tipo_maquina,JSON.parse(localDatosMaquina).id)
  const { dataVueltasGPS,loadingInfoVueltasGPS } = useGetInfoVueltasGPS(format(fechaActual, "yyyy-MM-dd"),JSON.parse(localDatosMaquina).id);
  const { loadingMovimientosGPS, movimientosGPS } = useGetMovimientosGPS(JSON.parse(localDatosMina).idMina, JSON.parse(localDatosMaquina).id,format(fechaActual, "yyyy-MM-dd"));
  const { vicons, loading: loadingVicons } = useGetViconsByIdOperacion(JSON.parse(localDatosMina).idMina);
 
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const turnoTodos = {
    nombre: "turnotodos",
    hora_fin: "23:59:59",
    hora_inicio: "00:00:00",
    id: 0,
    id_mina: 0,
    id_tipo_turno: 0,
    orden: 0
  }
  const [turno, setTurno] = useState(turnoTodos);
  const [portales, setPortales] = useState([]);
  const datosMaqVacios = {
    nombre: "",
    modelo: "",
    tipo: "",
    contadorVueltas: 0,
    contadorHoras: 0,
    vueltas: [],
    horometros: [],
    fecha: "2020-05-05"
  }
  
  const [datosMaquina, setDatosMaquina] = useState(datosMaqVacios);

  const [seriesMovilizacionPorTurno, setSeriesMovilizacionPorTurno] = useState([]);
  const [tiemposAsarco, setTiemposAsarco] = useState({});
  const [tiemposAsarcoPorTurno, setTiemposAsarcoPorTurno] = useState({
    "nominal": 0,
    "disponible": 0,
    "indisponible": 0,
    "operativo": 0,
    "reserva": 0,
    "efectivo": 0,
    "perdida_operacional":0,
    "ralenti":0,
    "ratio":0
  });
  const [kpiProd, setKpiProd] = useState({vueltas:{},tonelaje:{},rendimiento:{},horas:{},cargas:{}});
  const [portalesRows,setPortalesRows] = useState([]);
  const [configColumnas, setConfigColumnas] = useState([]);
  const defaultConfigHeadCols = {
    vueltas: [{ field: 'vueltasTotal' }],
    tonelaje: [{ field: 'tonelajeTotal' }],
    tiempo: [{ field: 'tiempoTotal' }],
    rendimiento: [{ field: 'rendimientoTotal'}],
    vueltasPromedio:[{field:'vueltasPromedioTotal'}],
    cargadorDumper:[]
  }
  // const defaultConfigHeadColsBotaderos = {
  //   cargadorDumper:[]
  // }
 
  const [configHeadCols, setConfigHeadCols] = useState(defaultConfigHeadCols);
  const [estadosDataSeries, setEstadosDataSeries] = useState([]);
  const [limites, setLimites] = useState({min: undefined, max: undefined});
  const classes = useStyles();

  const {gruposJustificaciones,loadingGrupoJustificaciones} = useGetGruposMotivosJustificaciones(JSON.parse(localDatosMina).idMina);
  const { loadingEncuentrosGPS, EncuentrosGPS } = useGetEncuentroGPS(JSON.parse(localDatosMina).idMina,JSON.parse(localDatosMaquina).id,format(fechaActual, "yyyy-MM-dd"));
  let prodCargasDefault = {Dia:0,Noche:0,turnotodos:0}
  let prodCargasSeriesDefault = {Dia:[],Noche:[],turnotodos:[]}
  const [dataProdCargas, setDataProdCargas] = useState(prodCargasDefault)
  const [dataProdCargasSeries, setDataProdCargasSeries] = useState(prodCargasSeriesDefault);

  const generarColumnasTablaBotaderos= (v) =>{
      const baseColumna = {
        field: "",
        headerName: "",
        headerClassName:"cell-font",
        align: "center",
        headerAlign: 'center',
        valueFormatter: params => params?.value,       
        flex:1
      }


      if(v.cargador && v?.cargador.nombre_cargador){
      if(v?.cargador?.vuelta?.beacon_inicio){

          setConfigColumnas(prevState => {
            // Verificar si el elemento ya existe en el array
            const existeElemento = prevState.some(obj => obj.field === v.cargador.vuelta.beacon_inicio);
    
            if (!existeElemento) {
              return [
                ...prevState,
                { ...baseColumna, field: v.cargador.vuelta.beacon_inicio, headerName: v.cargador.vuelta.beacon_inicio,minWidth:70 }
              ];
            } else {
              return prevState;
            }
          });

          setConfigHeadCols(prevState => {
            // Verificar si el elemento ya existe en el array
            const existeElemento = prevState.cargadorDumper.some(obj => obj.field === v.cargador.vuelta.beacon_inicio);
  
            if (!existeElemento) {
              return {
                ...prevState,
                cargadorDumper:[...prevState.cargadorDumper,{'field':v.cargador.vuelta.beacon_inicio}] 
              };
            } else {
              return prevState;
            }
          });
        
      }
  }
  

  }

  const generaColumnas = (turnos) => {
    const cols = [

    ]

    const baseColumna = {
      field: "",
      headerName: "",
      headerClassName:"cell-font",
      align: "center",
      headerAlign: 'center',
      valueFormatter: params => params?.value.toFixed(0),       
      flex:1
    }

    const tipos = [ 
      {des:'vueltas',cifras:false},
      {des:'tonelaje',cifras:false},
      {des:'tiempo',cifras:true},
      {des:'rendimiento',cifras:true},
      {des:'vueltasPromedio', cifras:true}
    ]

    const headCols = {...defaultConfigHeadCols}
    
    tipos.forEach( tipo => {
      turnos.forEach( turno => {
        const fields = [...headCols[tipo.des]]
        fields.push({field:`${tipo.des}Turno${turno.nombre}`})
        headCols[tipo.des] = fields
        cols.push({
          ...baseColumna,
          field: `${tipo.des}Turno${turno.nombre}`,
          headerName: `turno_${turno.nombre.toLowerCase()}`,
          valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
        })
      })
      cols.push({
        ...baseColumna,
        field: `${tipo.des}Total`,
        headerName: "Total",
        headerClassName:tipo.des!=="rendimiento"?"border-right-header cell-font":"cell-font",
        cellClassName: "cell-total",
        valueFormatter: params => tipo.cifras?(Math.round((params?.value)*10)/10).toLocaleString('de-DE'):(Math.round((params?.value))).toLocaleString('de-DE'),
      })
    })

    setConfigHeadCols(headCols)
    setConfigColumnas(cols)

  }
  const procesarCargas = (fecha,idMina,id_maquina) =>{
    const resultadoTotalAux ={Dia: 0,Noche:0,turnotodos:0};
    const resultadoTotalSeriesAux = {Dia:[],Noche:[],turnotodos:[]};
    getCargasByIdMaquina(idMina,fecha,id_maquina).then(({success, data}) => {
      if(success){
        data.forEach(item => {
            if (item.turno === "Dia") {
                resultadoTotalAux.Dia +=1;
                resultadoTotalSeriesAux.Dia.push(item)
            } else if (item.turno === "Noche") {
              resultadoTotalAux.Noche +=1;
              resultadoTotalSeriesAux.Noche.push(item)
            }
            resultadoTotalAux.turnotodos +=1;
            resultadoTotalSeriesAux.turnotodos.push(item)
        });
        
        setDataProdCargas({...resultadoTotalAux})
        setDataProdCargasSeries({...resultadoTotalSeriesAux})
      }
    })    
  }
  const procesarVueltasMaquina = (datosDelDia, turnos, portales, fecha,idMina) => {
    setloadingKpiDiario(true)
    const dataRows = {
      vueltasTotal: 0,
      tonelajeTotal: 0,
      tiempoTotal: 0,
      rendimientoTotal: 0,
      vueltasPromedioTotal:0
    }

    turnos.forEach( t => {
      dataRows[`vueltasTurno${t.nombre}`] = 0
      dataRows[`tonelajeTurno${t.nombre}`] = 0
      dataRows[`tiempoTurno${t.nombre}`] = 0
      dataRows[`rendimientoTurno${t.nombre}`] = 0
      dataRows[`vueltasPromedioTurno${t.nombre}`] = 0
    })

    const dataRowsWithKeys= portales.reduce((obj, item) => ((obj[item] = {id:`id${item}`,nombre:item,...dataRows}, obj)),{});

    getVueltasMaquinaRango(idMina,datosDelDia.id,format(fecha, "yyyy-MM-dd"),format(fecha, "yyyy-MM-dd")).then(({success, data}) => {
  //promedio de vueltas por hora, es cantidad de vueltas / en horas del turnos
  if(success){
    const keyVueltas = idMina == 24 ? "descripcion" : "ubicacion"
    const counts = {};
        setVueltasMaquinas(data);
        /* RELLENAR FILAS TABLA */
        data.forEach( v => {
          generarColumnasTablaBotaderos(v)
          
          // Turnos
          dataRowsWithKeys[v[keyVueltas]][`vueltasTurno${v.turno}`] += 1
          dataRowsWithKeys[v[keyVueltas]][`vueltasTotal`] += 1
          // Toneladas
          dataRowsWithKeys[v[keyVueltas]][`tonelajeTurno${v.turno}`] += Number(datosDelDia.toneladas)
          dataRowsWithKeys[v[keyVueltas]][`tonelajeTotal`] += Number(datosDelDia.toneladas)
          // Tiempo efectivo
          dataRowsWithKeys[v[keyVueltas]][`tiempoTurno${v.turno}`] += v.duracion_minutos/60
          dataRowsWithKeys[v[keyVueltas]][`tiempoTotal`] += v.duracion_minutos/60


          if(!dataRowsWithKeys[v[keyVueltas]][v.cargador.vuelta.beacon_inicio]){
            dataRowsWithKeys[v[keyVueltas]][v.cargador.vuelta.beacon_inicio] = 1
          }else if(dataRowsWithKeys[v[keyVueltas]][v.cargador.vuelta.beacon_inicio] >=0){
            dataRowsWithKeys[v[keyVueltas]][v.cargador.vuelta.beacon_inicio] +=1
          }

          // Iterar sobre todas las ubicaciones
          for (const keyVueltas in dataRowsWithKeys) {
            if (!dataRowsWithKeys[keyVueltas].hasOwnProperty(v.cargador.vuelta.beacon_inicio)) {
                // Si la ubicación no tiene v.cargador.vuelta.beacon_inicio, establecerlo en 0
                dataRowsWithKeys[keyVueltas][v.cargador.vuelta.beacon_inicio] = 0;
            }

          }

          
        })

       


        // Rendimiento
        portales.forEach( p => {
          const auxTon = dataRowsWithKeys[p][`tonelajeTotal`]
          const auxTime = ((dataRowsWithKeys[p][`tiempoTotal`]!==0)?dataRowsWithKeys[p][`tiempoTotal`]:1)
          const auxVueltas = dataRowsWithKeys[p]['vueltasTotal'];
          dataRowsWithKeys[p][`rendimientoTotal`] = auxTon / auxTime
          dataRowsWithKeys[p]['vueltasPromedioTotal'] = (auxTime && auxVueltas) ? ((auxTime * 60) / auxVueltas) / 60 : 0;
          turnos.forEach( t => {
            dataRowsWithKeys[p][`rendimientoTurno${t.nombre}`] = 
              dataRowsWithKeys[p][`tonelajeTurno${t.nombre}`] / 
              ((dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]!==0)?dataRowsWithKeys[p][`tiempoTurno${t.nombre}`]:1)
         
              //promedioVueltas*tiempoEfectivo
              dataRowsWithKeys[p][`vueltasPromedioTurno${t.nombre}`] = 
              (((dataRowsWithKeys[p][`tiempoTurno${t.nombre}`] * 60) /dataRowsWithKeys[p][`vueltasTurno${t.nombre}`])/60) ?
              ((dataRowsWithKeys[p][`tiempoTurno${t.nombre}`] * 60) /dataRowsWithKeys[p][`vueltasTurno${t.nombre}`])/60 :
              0
         
            })
        })

        setPortalesRows(Object.values(dataRowsWithKeys))

        /* FIN RELLENAR FILAS TABLA */

        /* RELLENAR KPIS */

        // Crea objeto con nombre de cada turno como key y valor 0
        let objTurnos = turnos.reduce((obj, item) => ((obj[item.nombre] = 0, obj)),{})  
        // Agrega a objeto el turnotodos
        objTurnos["turnotodos"] = 0;
        // Crea objeto con kpi como key y como valor los turnos
        let objKpis = {vueltas:{...objTurnos},tonelaje:{...objTurnos},rendimiento:{...objTurnos}, horas:{...objTurnos}}
        // Para acumular el tiempo efectivo de todos los turnos
        data.forEach((vuelta) => {
          if(vuelta.turno){
            objKpis["vueltas"][vuelta.turno] += 1
            objKpis["tonelaje"][vuelta.turno] += datosDelDia.toneladas
            objKpis["horas"][vuelta.turno] += vuelta.duracion_minutos/60
          }
          objKpis["vueltas"]["turnotodos"] += 1
          objKpis["tonelaje"]["turnotodos"] += datosDelDia.toneladas
          objKpis["horas"]["turnotodos"] += vuelta.duracion_minutos/60
        })
        objKpis["rendimiento"]["turnotodos"] += objKpis["tonelaje"]["turnotodos"] / objKpis["horas"]["turnotodos"];
        turnos.forEach( t => {
          objKpis["rendimiento"][t.nombre] += (objKpis["tonelaje"][t.nombre] / objKpis["horas"][t.nombre]) || 0;
        })
        
        setKpiProd(objKpis)
        setloadingKpiDiario(false)
        /* FIN RELLENAR KPIS */

      }else{
        setloadingKpiDiario(false)
      }
    })
  } 

  
  const getEstadosTotalDia = (estados) => {
    let totales = []
    Object.values(estados).forEach( valueTurno => {
      totales = [...totales, ...valueTurno]
    })
    return totales;
  } 


  const getDataSeriesEstados = (estados, lim) => {

    const datosEstados = [...estados]
    const dataOperativos = [];
    const dataRalenti =[];
    const dataMantencion = [];
    const dataFueradeServ = [];
    const dataSinInfo = [];
    const dataJustificada =[];

    // Array para guardar los ultimos estados de cada maquina
    // Se itera por cada maquina
  
      // Ordena valores por fecha
      const sortedValues = sortByDate(datosEstados,"fin");
      // Horarios de turno con su fecha tipo Date
      //const minmax = getMinMax(seriesTurno);
      const fechaInicioTurno = lim.min
      const fechaFinTurno = lim.max

      // Ultima hora para analizar estados sin info
      let ultimaHoraSinInfo = lim.min
      sortedValues.forEach(estado => {

        const data = {
          x: "horometro",
          y:[
            (new Date(estado.inicio)).getTime(),
            (new Date(estado.fin)).getTime()
          ],
          id_t_registro_movimiento:estado.id_t_registro_movimiento,
          id_tipo_movimiento:estado.id_tipo_movimiento
        }
        if(!estado.tipo_justificacion){
            switch(estado.movimiento){
              case "operativo":
                dataOperativos.push(data);
                break;
              case "mantencion":
                dataMantencion.push(data);
                break;
              case "fuera de servicio":
                dataFueradeServ.push(data);
                break;
              case "ralenti":
                dataRalenti.push(data);
              default:
                break
            }
        }else if(estado?.tipo_justificacion){
          (estado.tipo_justificacion.toLowerCase() == "otro") 
           ? dataJustificada.push({...data,justificacion:estado?.grupo_justificacion,tipo_justificacion:estado?.observacion}) 
           : dataJustificada.push({...data,justificacion:estado?.grupo_justificacion,tipo_justificacion:estado?.tipo_justificacion});
       }

         /* 
         Si hay una diferencia de tiempo entre la hora final del 
         estado anterior y la inicial del actual, agrega estado sin info 
         */
        if(((new Date(estado.inicio)).getTime() - ultimaHoraSinInfo.getTime())/1000>1){
          dataSinInfo.push({
            x: "horometro",
            y:[
              ultimaHoraSinInfo.getTime(),
              (new Date(estado.inicio)).getTime(),
            ]
          })
        }

        ultimaHoraSinInfo = new Date(estado.fin)

      })

      /* 
      Si la maquina no tiene estados, agrega estado sininfo con el largo del inicio y fin de turno
      Si tiene datos, comprueba si hay un valle de tiempo entre el fin de ultimo estado y el fin del turno y agrega un estado sininfo
      */
      if(sortedValues.length===0){
        dataSinInfo.push({
          x: "horometro",
          y:[
            fechaInicioTurno.getTime(),
            fechaFinTurno.getTime()
          ]
        })
      }
      else{
        const finEstadoFinal = new Date(sortedValues[sortedValues.length-1].fin)
        if((fechaFinTurno.getTime() - finEstadoFinal.getTime())/1000>1){
          dataSinInfo.push({
            x: "horometro",
            y:[
              finEstadoFinal.getTime(),
              fechaFinTurno.getTime(),
            ]
          })
        }
      }

    return([
      {
        name: 'operativo',
        color: "#01b7c4",
        data: dataOperativos
      },
      {
        name: 'en_mantencion',
        color: "#ffbf00",
        data: dataMantencion
      },
      {
        name: 'fuera_de_servicio',
        color: "#c5283d",
        data: dataFueradeServ
      },
      {
        name: 'sin_info',
        color: "#d6dce4",
        data: dataSinInfo
      },
      {
        name: 'ralenti',
        color: "#e9724c",
        data: dataRalenti
      },
      {
        name:'justificado',
        color:'#28B463',
        data:dataJustificada
      }
    ])

  } 

  const procesarMovimientosMaquina = (idMaq, fechaMaq, lim, idMina) => {
    const resEstados = getMovimientosPorMaquina(idMina, idMaq, fechaMaq, fechaMaq);
    resEstados.then(({success,data}) => {
      if(success && data[fechaMaq]){
        const estadosTotales = getEstadosTotalDia(data[fechaMaq]);
        setEstadosDataSeries(getDataSeriesEstados(estadosTotales, lim));
        setLoadHorizontalChart(false);
      }
    })
  }

  const getMinMaxTurnos = (turnos) => {
    const sortedTurnos =  turnos.sort(function(a, b) { 
      return a.orden - b.orden;
    });

    return {min: new Date(sortedTurnos[0].inicio), max: new Date(sortedTurnos[turnos.length-1].fin)}
  }

  const procesarAsarco = (tiempos, turnoSelec) => {
    // solucionar cuando llega objeto vacio
    const auxAsarco = {
      nominal: 0,
      disponible: 0,
      indisponible: 0,
      operativo: 0,
      reserva: 0,
      efectivo: 0,
      perdida_operacional:0,
      ralenti:0,
      ratio:0
    }

    if(Object.values(tiempos).length>0){
      if(turnoSelec === turnoTodos){
        let totalOperativo=0;
        let totalRalenti = 0;
        Object.values(tiempos).forEach( t => {
          totalOperativo += t["operativo"] ? t["operativo"] : 0;
          totalRalenti += t["ralenti"] ? t["ralenti"] : 0;
          auxAsarco["nominal"]              += t["nominal"]?t["nominal"]:0
          auxAsarco["disponible"]           += t["disponible"]?t["disponible"]:0
          auxAsarco["indisponible"]         += t["indisponible"]?t["indisponible"]:0
          auxAsarco["operativo"]            += t["operativo"]?t["operativo"]:0
          auxAsarco["reserva"]              += t["reserva"]?t["reserva"]:0
          auxAsarco["efectivo"]             += t["efectivo"]?t["efectivo"]:0
          auxAsarco["perdida_operacional"]  += t["perdida_operacional"]?t["perdida_operacional"]:0
          auxAsarco["ralenti"]              += t["ralenti"]?t["ralenti"]:0
        })
          auxAsarco["ratio"]                =totalRalenti !== 0 ? totalOperativo / totalRalenti : 0;

      }
      else{
        const asarcoPorTurno = tiempos[turnoSelec.nombre]
        auxAsarco["nominal"]              += asarcoPorTurno["nominal"]?asarcoPorTurno["nominal"]:0
        auxAsarco["disponible"]           += asarcoPorTurno["disponible"]?asarcoPorTurno["disponible"]:0
        auxAsarco["indisponible"]         += asarcoPorTurno["indisponible"]?asarcoPorTurno["indisponible"]:0
        auxAsarco["operativo"]            += asarcoPorTurno["operativo"]?asarcoPorTurno["operativo"]:0
        auxAsarco["reserva"]              += asarcoPorTurno["reserva"]?asarcoPorTurno["reserva"]:0
        auxAsarco["efectivo"]             += asarcoPorTurno["efectivo"]?asarcoPorTurno["efectivo"]:0
        auxAsarco["perdida_operacional"]  += asarcoPorTurno["perdida_operacional"]?asarcoPorTurno["perdida_operacional"]:0
        auxAsarco["ralenti"]              += asarcoPorTurno["ralenti"]?asarcoPorTurno["ralenti"]:0
        auxAsarco["ratio"]                += asarcoPorTurno["ratio"]?asarcoPorTurno["ratio"]:0
      }
      setTiemposAsarcoPorTurno(auxAsarco)
    }
  } 

  const getAsarco = (idMina, idMaq, inicio, fin, t) => {
    const resAsarco = getTiemposAsarco(idMina, idMaq/* 12 */, inicio, fin)
    resAsarco.then(({success,data}) => { 
      if(success){
        setTiemposAsarco(data[inicio]||{})
        procesarAsarco(data[inicio]||{}, t)
      } 
    })
  }

  useEffect(() => {
    const objDatosMina = JSON.parse(localDatosMina);
    const esMinaSubterrane = objDatosMina.esMinaSubterrane;
    const nombreMina = objDatosMina.nombreMina;
    const idMina = objDatosMina.idMina;
    onHandleSetDataGeneral(esMinaSubterrane,nombreMina,idMina);
    
  }, [localStorage.getItem("dataOpMaq")])
  

  useEffect(()=>{
    if(localDatosMaquina){
      const objDatosMaquina = JSON.parse(localDatosMaquina);
      const objDatosMina = JSON.parse(localDatosMina);
      const nombreMina = objDatosMina.nombreMina;
      const idMina = objDatosMina.idMina;
      const idMaquina = objDatosMaquina.id;
      const datosDia = objDatosMaquina;
      const rutaMina = objDatosMina.rutaMina;
      const esMinaSubterrane = objDatosMina.esMinaSubterrane;
      onHandleSetDataGeneral(esMinaSubterrane,nombreMina,idMina);
      setRutaMina(rutaMina);
      setDatosMaquina(datosDia);
      setNombreMina(nombreMina);
      setIdMina(idMina);
      procesarCargas(format(fechaActual, "yyyy-MM-dd"),idMina,idMaquina);
      getInfoGeneralMina(idMina,format(fechaActual, "yyyy-MM-dd"))
        .then(({success, data}) => {
          if(success){
            setLoadHorizontalChart(true);
            setListaTurnos(data.turnos);
            setPortales(data.portales);
            setLimites(getMinMaxTurnos(data.turnos))
            generaColumnas(data.turnos)
            setReglasVueltas(data.reglasVueltas);
            let reglasVueltasAux = data?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == objDatosMaquina?.tipo).map((regla) => regla.descripcion);
            let ejeY = idMina == 24 ? reglasVueltasAux : data.ubicaciones
            procesarVueltasMaquina(datosDia, data.turnos, ejeY, fechaActual,idMina)
            procesarMovimientosMaquina(idMaquina, format(fechaActual, "yyyy-MM-dd"), getMinMaxTurnos(data.turnos),idMina)
            getAsarco(idMina, idMaquina, format(fechaActual, "yyyy-MM-dd"), format(fechaActual, "yyyy-MM-dd"), turnoTodos)
          }
        })
    }
  },[reloadCharts,localStorage.getItem("dataOpMaq")]);



  useEffect(() => {
    if(idMina == "4"){
      setImagenCamion(imgCamionDumper);
    }
  }, [idMina]);

  useEffect(() => {
    return () => {
      sendTime(location.pathname)
    }
  }, [])
  

  const onChangeTurno = (t) => {
    setTurno(t);
    setLimites((t===turnoTodos)?getMinMaxTurnos(listaTurnos):{min: new Date(t.inicio), max: new Date(t.fin)})
    setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(seriesMovilizacionMaquina,t));
    procesarAsarco(tiemposAsarco, t)
  }

  const filtrarSeriesMovilizacionPorTurno = (movilizaciones,t) => {
    var series;
    if(t === turnoTodos){
      series = [];
      for(const [nombre_turno, movilizaciones_turno] of Object.entries(movilizaciones)){
        series = series.concat(movilizaciones_turno);
      }
    }
    else{
      series = movilizaciones[t.nombre];
    }
    return series?series:[];
  }



  const updateDataMovilizacionMaquina = (idMaquina,fecha) => {
    setLoadingMovimientos(true);
    if(idMaquina){
      getEncuentrosMovMaquina(idMaquina,fecha)
      .then(({success, data}) => {
        if(success){
          setSeriesMovilizacionMaquina(data["beacons"]);
          setSeriesMovilizacionPorTurno(filtrarSeriesMovilizacionPorTurno(data["beacons"], turnoTodos));
        }
        else{
          console.error("Ocurrio un error");
        }
        setLoadingMovimientos(false)
      })  
    }
    else{
      console.error("No se ha seleccionado ninguna máquina");
    }
  };


  const getMinMaxGrafico = (fechaActual) => {
    let fechaInicio = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${turno.hora_inicio}`);
    let fechaFin = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${turno.hora_fin}`);
    switch(Number(turno.id_tipo_turno)){
      case 1:
        // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
        fechaInicio.setDate(fechaInicio.getDate() - 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      case 2:
        // El turno es dentro de horario de un mismo dia
        return ({
          min: fechaInicio,
          max: fechaFin
        })
        // Case 3 representaria turno entre dos dias que termina en el dia siguiente
      case 3:
        fechaFin.setDate(fechaFin.getDate() + 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      default:
        // El turno tiene id 0 o distinto, por lo tanto, se deben mostrar todos los datos
        listaTurnos.forEach(itemTurno => {
          switch(itemTurno.orden){
            case 0:        
              // Primer turno      
              fechaInicio = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${itemTurno.hora_inicio}`);
              if(Number(itemTurno.id_tipo_turno)===1){
                fechaInicio.setDate(fechaInicio.getDate() - 1);
              }
            case (Number(listaTurnos.length)-1):
              // Ultimo turno
              fechaFin = new Date(`${format(fechaActual, "yyyy-MM-dd")} ${itemTurno.hora_fin}`);
              if(Number(itemTurno.id_tipo_turno)===3){
                fechaFin.setDate(fechaFin.getDate() + 1);
              }
            default:
              // Turnos intermedios no se consideran
              break;
          }
        })

        return({
          min: fechaInicio,
          max: fechaFin
        })
    }
  };

  const minToHours = (minutes) => {
    return Math.round((minutes/60) * 100) / 100/* (minutes/60).toFixed(2); */
  }

  useEffect(()=>{
    if(datosMaquina.nombre !== ""){
      updateDataMovilizacionMaquina(datosMaquina.id, format(fechaActual, "yyyy-MM-dd"));
    }
  },[datosMaquina]);
  
  const onChangeFecha = (fecha) => {
    setFechaActual(fecha);
    setTurno(turnoTodos);
    setLoadingMovimientos(true);
    if(localDatosMaquina){
      const objDatosMaquina = JSON.parse(localDatosMaquina);
      const objDatosMina = JSON.parse(localDatosMina);
      const idMina = objDatosMina.idMina;
      const idMaquina = objDatosMaquina.id;
      const datosDia = objDatosMaquina;
      const objIngresoGeneral ={}
      procesarCargas(format(fecha, "yyyy-MM-dd"),idMina,idMaquina);
      getInfoGeneralMina(idMina,format(fecha, "yyyy-MM-dd"))
        .then(({success, data}) => {
          if(success){
            setReglasVueltas(data.reglasVueltas)
            let reglasVueltasAux = data?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == objDatosMaquina?.tipo).map((regla) => regla.descripcion);
            let ejeY = idMina == 24 ? reglasVueltasAux : data.ubicaciones
            setPortales(data.portales);
            setLoadHorizontalChart(true);
            setListaTurnos(data.turnos);
            setLimites(getMinMaxTurnos(data.turnos))
            generaColumnas(data.turnos)
            procesarVueltasMaquina(datosDia, data.turnos, ejeY, fecha, idMina)
            procesarMovimientosMaquina(idMaquina, format(fecha, "yyyy-MM-dd"), getMinMaxTurnos(data.turnos),idMina)
            updateDataMovilizacionMaquina(idMaquina, format(fecha, "yyyy-MM-dd"));
            getAsarco(idMina, idMaquina, format(fecha, "yyyy-MM-dd"), format(fecha, "yyyy-MM-dd"), turnoTodos)
          }
        })
    }

  }

  const [stateInicialFormJustificaciones, setstateInicialFormJustificaciones] = useState({
    fecha_inicio:'',
    fecha_termino:'',
    id_movimiento:0,
    id_registro_movimiento:0,
    id_maquina:JSON.parse(localDatosMaquina).id,
    nombre_motivo:'',
    ubicacion:''
   })

    const handleClickOpen = (id_movimiento, fecha_inicio, fecha_termino, id_t_registro_movimiento) => {
    
      setOpen(true);
    setLoadingPopUp(true);

    setstateInicialFormJustificaciones(prevState =>{
      return {
        ...prevState,
        fecha_inicio,
        fecha_termino,
        id_movimiento,
        id_t_registro_movimiento,
        id_maquina:JSON.parse(localDatosMaquina).id
      }
    });

    getUltimaUbicacionJustificacion(idMina, id_t_registro_movimiento)
    .then((resp) => {
      setstateInicialFormJustificaciones(prevState =>{
        return {
          ...prevState,
          ubicacion: resp.data.nombre
        }
      })
      setLoadingPopUp(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadingPopUp(false);
    });

  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };
  return(
    <>{
      localDatosMaquina?
        <>
          <NavBar />
          <Container>

            {/* Formulario popup */}
            <PopupRegistroJustificacion
              FormJustificados={false}
              classes={classes}
              loadingPopUp={loadingPopUp}
              open={open}
              gruposJustificaciones={gruposJustificaciones}
              verCheckbox={true}
              stateInicialFormJustificaciones={stateInicialFormJustificaciones}
              setReloadCharts={setReloadCharts}
              setLoadHorizontalChart={setLoadHorizontalChart}
              setOpen={setOpen}
              setAlert={setAlert}
            />

            {/* Alerta de form */}
            <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />

            <br/>

            <BreadCrumbsGenerico
            nombreMina={nombreMina}
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
              { id: 3, endpoint: idMina != 24 ? `/operaciones/${rutaMina}` : `/control_diario/${rutaMina}`, texto:idMina != 24 ? t("operaciones") : t("control_diario")},
              { id: 4, texto: translateMachineName(datosMaquina?.nombre,t,idMina), destacar:true }
            ]}
            />

            <br/>
            <br/>

            <Grid container justifyContent="flex-end">
              <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.datepicker}
                  format="dd/MM/yyyy"
                  cancelLabel={t("cancelar")}
                  size="small"
                  inputVariant="outlined"
                  value={fechaActual}
                  onChange={onChangeFecha}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <br/>

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={0}
            > 
              <Grid item lg={5}>
                <Grid container alignItems="center">
                  <Grid item>
                  <CardMedia
                      component="img"
                      image={(datosMaquina.tipo==="Camion"|| datosMaquina.tipo == "Telehandler" || datosMaquina.tipo == "Hormigonera" || datosMaquina.tipo == "Extractor" || datosMaquina.tipo =="Auxiliar") ?
                            imagenCamion:
                            datosMaquina.tipo==="LHD"?ImagenEquipo:datosMaquina.tipo==="Jumbo"?ImagenJumbo:""}
                      alt="Imagen máquina"
                      style={{ width: (datosMaquina.tipo==="Camion"|| datosMaquina.tipo == "Telehandler" || datosMaquina.tipo == "Hormigonera")?"80px":"120px", marginRight: "20px" }}
                    />
                  </Grid>

                  <Grid item>
                    <Grid 
                      container
                      direction="column"
                    >
                      <Grid item>
                        <Typography
                          style={{
                            fontWeight: "bold",
                            fontSize: "18px",
                            color: "#00B7C4"
                          }}
                        >
                          {translateMachineName(datosMaquina.nombre, t,idMina)}
                        </Typography>
                      </Grid>
                      <SelectorFechaFlechas fechaActual={fechaActual} onChangeFecha={onChangeFecha} restarDias={() =>restarDias(fechaActual,1)} sumarDias={()=>sumarDias(fechaActual,1)} />
                    </Grid>
                  </Grid>
                </Grid>
              
              </Grid>
              <Grid item lg = {7}>
                <Grid container justifyContent='flex-end' alignItems="center">
                  <Button
                      onClick={()=>onChangeTurno(turnoTodos)}
                      className={turno.id===0?classes.buttonSelected:classes.buttonUnselected}
                  >
                    {t("todos")}
                  </Button>

                  {
                    listaTurnos.map((itemTurno,index) => {
                      return(
                          <Button
                              key={index+1}
                              onClick={()=>onChangeTurno({id: index+1, ...itemTurno })}
                              className={(turno.nombre===itemTurno.nombre)?classes.buttonSelected:classes.buttonUnselected}
                          >
                            {t(`turno_${itemTurno.nombre.toLowerCase()}`)}
                          </Button>
                      )
                    })
                  }
                </Grid>
              </Grid>

            </Grid>
            <br/>
            <Card sx={{padding:"30px 20px 20px 20px"}}>
              <CardContent>
                <SectionHeader
                  title={t("kpis_de_prod_diaria")}
                  subtitle={""}
                  icon={<img src={IconoKpi} width="35px" alt=" Icono Resumen Diario"/>}
                />
                <br/>
                <br/>
                {
                  loadingKpiDiario ?
                      <>
                        <Grid container justifyContent="center" alignItems="center">          
                          <CircularProgress/>
                        </Grid> 
                      </>  :
                (
                        <Grid container sx={{backgroundColor:"#2f4554", borderRadius:"2px"}}>
                          <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                            <ProdKpiCard 
                              titulo={JSON.parse(localDatosMaquina).tipo_maquina == 1 ? t("cargas") : t("vueltas")}
                              subtitulo={`(${t("cantidad")})`} 
                              dataTurnos={JSON.parse(localDatosMaquina).tipo_maquina == 1 ? dataProdCargas : kpiProd.vueltas}
                              isSelect = {true}
                              icono={<img src={IconoVueltas} width="18px" alt=" icono vueltas"/>}
                              turno={turno.nombre}
                            />               
                          </Grid>
                          <Grid item lg={4} md={12} xs={12} sx={{borderRight:"1px solid #465f70"}}>
                            <ProdKpiCard 
                              titulo={t("tonelaje")}
                              subtitulo="(T)" 
                              dataTurnos={kpiProd.tonelaje}
                              isSelect = {true}
                              icono={<img src={IconoTonelaje} width="18px" alt=" icono vueltas"/>}
                              turno={turno.nombre}
                            />    
                          </Grid>
                          <Grid item lg={4} md={12} xs={12}>
                            <ProdKpiCard 
                              titulo={t("rendimiento")}
                              subtitulo="(T/h)" 
                              dataTurnos={kpiProd.rendimiento}
                              isSelect = {true}
                              icono={<img src={IconoRendimiento} width="23px" alt=" icono vueltas"/>}
                              turno={turno.nombre}
                            />    
                          </Grid>
                        </Grid>

                      )
                }

                <br/>
                <br/>
                <TiempoAsarcoGenerico tiemposAsarcoPorTurno={tiemposAsarcoPorTurno} mostrarRatio={false}/>

                <br/>
                <br/>
                  {
                    (JSON.parse(localDatosMaquina).tipo_maquina == 2 || JSON.parse(localDatosMaquina).tipo_maquina == 1) &&

                    (loadingIngresoEgreso ? 
                      <>
                        <Grid container justifyContent="center" alignItems="center">          
                          <CircularProgress/>
                        </Grid> 
                        <br/>
                        <br/>
                      </> :
                      <>
                        <RegistroIngreso 
                          fecha_inicio={(dataIngresoEgreso && dataIngresoEgreso[turno.nombre] && dataIngresoEgreso[turno.nombre][JSON.parse(localDatosMaquina).nombre]?.fecha_ingreso) ? generateDatabaseDateTimeES(dataIngresoEgreso[turno.nombre][JSON.parse(localDatosMaquina).nombre]?.fecha_ingreso) : t("sin_info")}
                          fecha_termino={(dataIngresoEgreso && dataIngresoEgreso[turno.nombre] && dataIngresoEgreso[turno.nombre][JSON.parse(localDatosMaquina).nombre]?.fecha_salida) ? generateDatabaseDateTimeES(dataIngresoEgreso[turno.nombre][JSON.parse(localDatosMaquina).nombre]?.fecha_salida) : t("sin_info")}  
                          title1={JSON.parse(localDatosMaquina).tipo_maquina == 2 ? t("ingreso_mina") : t("primer_encuentro_frente")}
                          title2={JSON.parse(localDatosMaquina).tipo_maquina == 2 ? t("salida_mina") : t("ultimo_encuentro_frente")}
                        />

                        <br/>
                        <br/>
                      </>
                    )
                  }
                <Grid container style={{marginBottom:'20px'}} justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item>
                    <SectionHeader
                      title={t("movimiento_y_horometro")}
                      subtitle={""}
                      icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
                    />
                  </Grid>
                  {
                    cuentasAdministracionVueltas.includes(Pool?.getCurrentUser()?.username) && idMina == 24 && JSON.parse(localDatosMaquina).tipo_maquina == 2 && (
                    <Grid item>
                      <Box sx={{ maxWidth: '240px'}}>
                          <Button
                              fullWidth
                              type="button"
                              variant="contained"
                              className={classes.root}
                              onClick={() =>{
                                setVisibleModalVueltasManual(true)
                              }
                              }
                          >
                              <Grid container alignItems="center" >
                                  <Grid item>
                                      <ControlPointIcon style={{ color: 'white', display:'block', margin:'auto' }} />
                                  </Grid>
                                  <Grid item>
                                      {t("vuelta")}
                                  </Grid>
                              </Grid>
                          </Button>
                      </Box>
                    </Grid>
                    )
                  }
                </Grid>

                {
                  (loadingMovimientos || loadingEncuentrosGPS) ? (
                      <Grid container justifyContent="center" alignItems="center">          
                        <CircularProgress/>
                      </Grid>)
                    :
                    (seriesMovilizacionPorTurno.length > 0 || EncuentrosGPS[turno?.nombre]?.length >0 ||  dataProdCargasSeries[turno.nombre].length >0?
                        <MotionChart
                          series={seriesMovilizacionPorTurno}
                          limites={getMinMaxGrafico(fechaActual)}
                          fecha={format(fechaActual, "yyyy-MM-dd")}
                          esEncuentrosGPS={false}
                          vueltas={vueltasMaquinas}
                          seriesGPS={EncuentrosGPS[turno.nombre]}
                          seriesCargas={dataProdCargasSeries[turno.nombre]}
                        />
                      :
                        <BarTimelineChartVacio
                          limites={limites}
                          labels={[t('carga'), t('descarga')]}
                          height="300"
                          loading={!loading}
                        /> )
                }

              <div style={{width:"100%"}}>

              <br/>
              <br/>

              <LeyendasMovimientos/>


                </div>
                <br/>
              {
                (estadosDataSeries.length>0 && (!loadingMovimientos && !loadHorizontalChart)) ?
              
                  <HorizontalTimeChart
                    series = {estadosDataSeries} 
                    turno={limites}
                    numCategorias={1}
                    abrirModalJustificaciones={handleClickOpen}
                    MostrarLabelCompleto={false}
                  />
                :
                  <BarTimelineChartVacio
                    limites={getMinMaxGrafico(fechaActual)}
                    labels={[t("horometro")]}
                    height="130"
                    loading={(loadingMovimientos || loadHorizontalChart)}
                  />
              }
                {
                  datosMaquina && datosMaquina.es_subterranea !== undefined && !datosMaquina.es_subterranea && 
                  (
                    <>
                      <br/>
                      <br/>
                      <SectionHeader
                        title={t("Geolocalización")}
                        subtitle={""}
                        icon={<LocationSearchingIcon fontSize="large"/>}
                      />
                      <MultipleSimpleIndicator columnas={[{
                          titulo:"Cantidad de vueltas",
                          valor:dataVueltasGPS?.vueltas?.[turno?.nombre]?.length ? dataVueltasGPS?.vueltas?.[turno?.nombre]?.length : 0,
                          toolTip:undefined
                        },{
                          titulo:"Distancia promedio vueltas (km)",
                          valor:dataVueltasGPS?.distanciaPromedio?.[turno?.nombre] ? dataVueltasGPS?.distanciaPromedio?.[turno?.nombre]?.["promedio"] : 0,
                          toolTip:undefined
                        },{
                          titulo:"Tiempo promedio vueltas (hrs)",
                          valor:dataVueltasGPS?.tiempoPromedio?.[turno?.nombre] ? ConvertMinutosToHoras(dataVueltasGPS?.tiempoPromedio?.[turno?.nombre]?.["promedio"]) : 0,
                          toolTip:undefined
                        },{
                          titulo:"Velocidad promedio vueltas (km/h)",
                          valor:dataVueltasGPS?.velocidadPromedio?.[turno?.nombre] ? formatearCantidadDecimales(dataVueltasGPS?.velocidadPromedio?.[turno?.nombre]?.["promedio"],1) : 0,
                          toolTip:undefined
                        },{
                          titulo:"Velocidad promedio general (km/h)",
                          valor:movimientosGPS?.speedPromedio?.[turno?.nombre]?.["promedio"] ? movimientosGPS?.speedPromedio?.[turno?.nombre]?.["promedio"] : 0,
                          toolTip:undefined
                        }]}/>
                      <br/>
                      <br/>
                      <MapaGPS 
                        id_area_operacion = {JSON.parse(localDatosMina).idMina} 
                        id_maquina = {JSON.parse(localDatosMaquina).id}
                        fecha={format(fechaActual, "yyyy-MM-dd")}
                        loadingMovimientosGPS={loadingMovimientosGPS}
                        movimientosGPS={movimientosGPS}
                      />
                      <br/>
                    </>
                  )
                }
                <Grid>
                </Grid>
                <br/>
                <br/>
                {
                  datosMaquina && datosMaquina.es_subterranea !== undefined && !datosMaquina.es_subterranea && (
                    <>
                      <SectionHeader
                        title={t("detalle_vuelta")}
                        subtitle={""}
                        icon={<RouteOutlinedIcon fontSize="large"/>}
                        />
                        <br/>
                      <TablaVueltasGPS cargando={loadingInfoVueltasGPS} data={dataVueltasGPS?.vueltas?.[turno.nombre] ? dataVueltasGPS?.vueltas?.[turno.nombre] : []}/>
                      <br/>
                      <br/>
                    </>
                  )
                }
                {
                  JSON.parse(localDatosMaquina).tipo_maquina == 2 && (
                    <>
                      <SectionHeader
                        title={ idMina == 24 ? t("detalle_por_vuelta") : t("detalle_por_portal")}
                        subtitle={""}
                        icon={<img src={IconoDetalle} width="35px" alt=" Icono Resumen Diario"/>}
                      />
                      <br/>
                      <TablaV5 data={portalesRows} cols={configColumnas} headCols={configHeadCols}/>
                    </>
                  )
                }
              </CardContent>
            </Card>
            <br/>
          </Container>
          {
            vicons.length > 0 && datosMaquina && !loadingVicons &&
            <ModalVueltasManual 
            id_mina={idMina}
            maquina={datosMaquina}
            turnos={listaTurnos}
            vicons={vicons}
            portales={portales}
            reglasVueltas={reglasVueltas}
            setVisibilidadModal={setVisibleModalVueltasManual}
            visibilidadModal={VisibleModalVueltasManual}
            setReloadCharts={setReloadCharts}
          />
          }
        </>
      :
        <Redirect to={"/"}/>
    }</>
  ); 
};

export default OperacionesMaquina;
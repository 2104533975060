import React from "react";
import {
  Grid,
  Typography
} from "@mui/material";



const SectionHeader = ({title,subtitle,icon, colorTitle="#2f4554",aumentarFuente = 0, bold=false}) => {
  return(
      <Grid item container spacing={1} alignItems="center">
        <Grid item>
          {icon}
        </Grid>
        <Grid item lg={5} md={5} xs={5}>
          <Grid container>
            <Grid item lg={12} md={12} xs={12}>
              <Typography
                style={{fontWeight: "bold",fontSize:`${14.4+aumentarFuente}px`,color:colorTitle, marginBottom:subtitle?"0px":"10px",whiteSpace:'nowrap'}}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <div
                style={{fontStyle: "italic", fontWeight: bold ? "bold" :"",fontSize:`${12+aumentarFuente}px`,color: "#717171"}}
              >
                {subtitle}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default SectionHeader;